import React from "react";
import Employer from "../components/Employer";
import { Layout } from "../components/index";

export default class Workplace extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <Employer pageContext={this.props.pageContext}></Employer>
      </Layout>
    );
  }
}
